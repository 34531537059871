import styled from "styled-components";
import { HalfWH , HalfTitle, HalfVolanta } from "../mixins-modules"; 
import { breakpoints } from "@/shared/Constants";
export const ContentHalf = styled.div`
  ${HalfWH}
  flex-direction: row;
  justify-content: space-between;

 
  .mt{
    flex:1;
    max-width: 165px;
    padding-right: 10px;
    ${breakpoints.phone} {
      max-width:65%;
    }
    h2.title{
       ${HalfTitle}
       /* height: 80px;
       overflow: hidden; */
    }
    .volanta{
      ${HalfVolanta}
    }
    .bajada{
      display:none;
    }
    .data-txt{
      margin-top: 1px;
      flex-direction: column-reverse;
      align-items: flex-start!important;
      .ifPaywallPipe{
        &:before{
          display:none;
        }
      }
    }
 
  }
    .contentVideo{
      width:125px;
      height: 125px;
      flex-grow: 0;
      flex-shrink: 0;
    ${breakpoints.phone} {
      width:125px!important;
      height: 125px!important;
      min-height: 125px;

    }
    video {
      ${breakpoints.phone} {
        min-height: 125px;
        position:relative;
      }
      
    }
  }
  picture {
      width:135px;
      height: 135px;
      flex-grow: 0;
        flex-shrink: 0;
      ${breakpoints.phone} {
        width:125px;
        height: 125px;
      }
    }
    video {
      height: 100%;
    }
`;
