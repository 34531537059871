import styled from "styled-components";
import { Color, Font, breakpoints , ColorDark} from "@/shared/Constants";

export const ContentRelatedNews = styled.ul `
    position: relative;
    left: 7px;
    top: 6px;
    z-index: 4;
    margin-top: 5px;
    ${breakpoints.phone} {
         position:relative;
         left: 0;
    }
    li{
        margin-bottom:5px;
        a{
            ${Font.sansRegular};
            color:${Color.black};
            font-size:15px;
            line-height:20px;
            display: flex;
            align-items: baseline;
            transition-duration: .3s;
            ${breakpoints.darkMode} {
                [data-theme='dark'] & {
                color:${ColorDark.textLight};
                }
            }
            ${breakpoints.phone} {
                font-size:16px;
                line-height:21px;
            }
            &:hover{
              color:#666;
            }
            &:before {
                content:"●";
                /* width:5px;
                height:5px;
                border-radius:4px; */
                /* background:#000;
                display:block;
                margin-right: 7px; */
                margin-right: 5px;
                font-size:10px;
                transform: translate(-4px,-1px);
                ${breakpoints.phone} {
                    transform: translate(0px,-1px);
                }
            }
        }
    }


`    