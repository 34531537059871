import { ContentHalf } from "./styles";
import ContentImage from "@/shared/ContentImage";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import ContentTitle from "@/shared/ContentTitle";
import { NormalArticle } from "@/widgets/Nota/Templates/stylesModulesGenericNormal";
import VideoLoop from "@/shared/VideoLoop";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import useNewsDataModules from "@/hooks/useNewsDataModules";
const Half = (props) => {
  const {
    asPath,
    author = [],
    authorSignature,
    containerName,
    iconMultimedia,
    imagePreview,
    imageWeb,
    isPreview,
    payWallExclusive,
    section = [],
    subtitle,
    title,
    url,
    urlTarget,
    videosOpenLoop,
    videoLoop
  } = useNewsDataModules(props, {
    desktop: ["col_listado_1x1", "1_1_S2", "square_sm"],
    mobile: ["col_listado_1x1","1_1_S1", "square_sm"],
  });

  return (
    <NormalArticle>
      <ContentHalf className="half">
        <ContentTitle
          author={author}
          authorSignature={authorSignature}
          title={title}
          subtitle={subtitle}
          section={section}
          payWallExclusive={payWallExclusive}
        />
        {videosOpenLoop?.enabled ? 
        <VideoLoop videoLoop={videoLoop} />
        :<picture>
            {isPreview ? (
              imagePreview && <ContentImage iconMultimedia={iconMultimedia} data={imagePreview} alt={title} />
            ) : (
              <ContentImage iconMultimedia={iconMultimedia} data={imageWeb} alt={title} />
            )}
        </picture>}
      </ContentHalf>
      <LinkAbsolute href={url} target={urlTarget} aria-label={title} onClick={() => clickContent({contentType: title, contentList: containerName},url,asPath)}/>
    </NormalArticle>
  );
};

export default Half;
