import React, { useEffect, useState } from 'react'
import ContentAuthor from '../ContentAuthor'
import { getDateFormatForHead, datePost } from '@/helpers/formatDate'
import RelatedLilaNews from '@/shared/RelatedLilaNews'

const ContentTitle = (props) => {
  const {
    title,
    summary,
    subtitle,
    section,
    author,
    type,
    authorSignature,
    titleLocation = '',
    payWallExclusive,
    relatedLilaNews = [],
    cutTitle,
    showDate,
    dateHours = false,
    date,
  } = props

  const summaryWithoutHtml = summary ? summary.replace(/(<([^>]+)>)/gi, '') : ''
  const [formattedDate, setFormattedDate] = useState('')

  useEffect(() => {
    if (date && showDate) {
      const formattedDateValue = dateHours ? date : datePost(date)
      setFormattedDate(formattedDateValue)
    }
  }, [date, showDate, dateHours])

  const authorName =
    Array.isArray(author) &&
    author.length &&
    typeof author[0].name !== 'undefined'
      ? author[0].name
      : ''

  return (
    <div type={type} className={`mt ${titleLocation}`}>
      <h2 type={type} className={`title ${cutTitle ? 'shortenTitle' : ''}`}>
        {title
          ?.replace('"', '“')
          .replace('"', '”')
          .replace('"', '“')
          .replace('"', '”')}
      </h2>

      {summaryWithoutHtml && relatedLilaNews && (
        <div className="bottomSummary">
          {summaryWithoutHtml && <p className="bajada">{summaryWithoutHtml}</p>}
          <RelatedLilaNews content={relatedLilaNews} />
        </div>
      )}

      {showDate && formattedDate && (
        <span className="date">{formattedDate}</span>
      )}

      {subtitle && (
        <p className="volanta">
          {subtitle?.replace('"', '“').replace('"', '”')}
        </p>
      )}
      <ContentAuthor
        author={author}
        payWallExclusive={payWallExclusive}
        authorSignature={authorSignature}
        section={section}
      />
    </div>
  )
}

export default ContentTitle
