import { Video, ContentVideo} from "./styles";
import { useEffect, useRef } from "react";
const VideoLoop = ({videoLoop = "",  titleLocation = "",}) => {

const videoRef = useRef();
const entityIDdiv= "loop"+videoLoop?.entityId
  const installPlayerScript = () => {
    const jwPlayerScript = document.createElement("script");
    jwPlayerScript.id = "jwPlayerLibrary";
    jwPlayerScript.src = process.env.NEXT_PUBLIC_LOOP_JWPLAYER_ID;
    jwPlayerScript.async = true;
    jwPlayerScript.onload = () => {
      document.dispatchEvent(new CustomEvent('jwPlayerScriptLoaded'));
      initialize();
    };
    document.body.appendChild(jwPlayerScript);
  };


  const initialize = () => {
    window.removeEventListener("load", initialize);
    const player = window.jwplayer(videoRef.current);
    
    const playerOpts = {
      ...(videoLoop.url 
          ? { file: videoLoop.url } 
          : { playlist: `https://cdn.jwplayer.com/v2/media/${videoLoop.entityId}` }),
      image: ""
    };

    if (videoRef.current) {
      player.setup(playerOpts);     
    }
  };

  useEffect(() => {
    const existingLibraryScript = document.getElementById("jwPlayerLibrary");
    if (!existingLibraryScript) {
      installPlayerScript();
    } else if (window?.jwplayer) {
      initialize();
    } else {
      document.addEventListener('jwPlayerScriptLoaded', initialize, { once: true });
    }    
  }, []);

  if(!entityIDdiv){
    return false;
  }
 
  return (
    <ContentVideo className={` ${titleLocation} contentVideo`}> 
        <div id={entityIDdiv} ref={videoRef} />
    </ContentVideo>
  );
};

export default VideoLoop;
