import styled from "styled-components";
import {  Color } from "@/shared/Constants";



export const BoxAutor = styled.div`
    margin-top: 8px;
    align-items:center;
    flex-direction: row-reverse;
    justify-content: flex-end;
   
    .ifPaywallPipe {
        display:flex;
            &::before{
            content: "|";
            color: ${Color.grey};
            font-size: 15px;
            padding-right: 7px;
        }
    }
`;


