import React from "react";
import { ContentRelatedNews } from "./styles";

const RelatedLilaNews = ({ content }) => {
    if (!content || content.length === 0) {
        return null;
    }

    return (
        <ContentRelatedNews  className="related">
            {content.map((data, i) => {
                const url = data.url || (data.value && data.value.url);
                const title = data.title || (data.value && data.value.title);

                if (!url || !title) {
                    return null;
                }

                return (
                    <li key={i}><article><a href={url}>{title}</a></article></li>
                );
            })}
        </ContentRelatedNews>
    );
};

export default RelatedLilaNews;
