import styled from "styled-components";


import { Colors, Font, breakpoints } from "@/shared/Constants";

export const ContentVideo= styled.div `
    max-height: 100%;
    width: 100%;
    overflow:hidden;
    .jw-wrapper{
        background-color: transparent !important;
    }
`

export const Video= styled.video `
    display: block;
    position: relative;
    background: #000;
    height: 100%;
    width: 100%;
    object-fit: cover;
`
