import { css } from "styled-components";
import { breakpoints } from "@/shared/Constants";

//MIXINS PARA TODOS LOS 1x1
export const onexoneWH = css`
    width:307px;
    height:315px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    overflow:hidden;
    ${breakpoints.phone} {
        width:100%;
        height:100%;
      }
`;
export const onexoneBoxTtile = css`
      
    ${breakpoints.phone} {
        min-height: auto;
        margin: 10px 0 20px;
      }
`;
export const onexoneTitle = css`
       font-size: 21px;
    line-height: 24px;
    ${breakpoints.phone} {
          font-size: 22px;
          line-height: 25px;
      }
`;
export const onexoneData =css`
    margin-top: 12px;
    flex-direction: row-reverse;
    justify-content: flex-end;

`
export const onexoneVolanta = css`
    font-size: 21px;
    line-height: 24px;
    /* height: 24px;
    overflow: hidden; */
`;
export const onexonePicture = css`
    height: 148px;
    width:100%;
    /* overflow:hidden; */
    img{
        height:148px;
    }
   
    ${breakpoints.phone} {
        width:100%;
        height:auto;
        img{
            min-height:170px;
            height:auto;
        }
      }

`;

export const onexoneVideo = css`
      min-height: 145px;
      width:100%;
`;


//MIXINS PARA TODOS LOS 2x1 
export const TwoxOneWH = css`
        width:645px;
        height:309px;
        overflow:hidden;
      ${breakpoints.phone} {
        height:auto;
        width:100%;
      }
`;

//MIXINS PARA TODOS LOS HALF 
export const HalfWH = css`
     display:flex;
    height:140px;
    overflow:hidden;
    width:100%;
    flex-direction: column;
    ${breakpoints.phone} {
        height:auto;
        width:100%;
        margin-bottom:12px;
      }
`;

export const HalfTitle = css`
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 5px;
    ${breakpoints.phone} {
        font-size: 18px;
        line-height: 21px;
    }
`;


export const HalfVolanta= css`
    font-size: 17px;
    line-height: 20px;
    /* height: 21px;
    overflow: hidden; */
`

//MIXINS PARA TODOS LOS 2x2 (no-visual)
export const TwoxTwoWH = css`
    width:642px;
    height:649px;
    overflow:hidden;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    overflow:hidden;
      ${breakpoints.phone} {
        height:auto;
        width:100%;
      }
`;
export const TwoxTwoTitle = css`
     font-size: 33px;
      line-height: 37px;
      text-transform: inherit;
          ${breakpoints.phone} {
            font-size: 27px;
            line-height: 31px;
        }
`;

export const TwoxTwoVolanta = css`
      font-size: 33px;
      line-height: 37px;
      text-transform: inherit;
          ${breakpoints.phone} {
            font-size: 27px;
            line-height: 31px;
        }
`;

export const backgroundPhoto = css `
    content:"";
    position: absolute;
    width: 100%;
    height: 33%;
    border-radius: 0;
    background-image: linear-gradient(to bottom,transparent 0,transparent 40%,rgba(25,25,25,0.7),#212121);
    bottom: 0;
    left: 0;
`