import { BoxAutor } from "./styles";
import ContentPayWallExclusive from "../ContentPayWallExclusive";

const ContentAuthor = ({ author, authorSignature, payWallExclusive, section }) => {
    const sectionName = (Array.isArray(section) && section.length && typeof section[0].name !== 'undefined') 
        ? section[0].name 
        : "";
    const authorName = authorSignature ? authorSignature : (author?.[0]?.name ? author?.[0]?.name : sectionName );
    const nameClass = authorSignature ? authorSignature : (author?.[0]?.name ? "name-author" : "name-section" );
    return (
        <>
        <BoxAutor className="data-txt">
            {
                payWallExclusive && <div className="ifPaywallPipe"><ContentPayWallExclusive /></div> 
            }
            {authorName == "" && payWallExclusive ? 
                <p className={`name ${nameClass}`}>REDACCIÓN CLARÍN</p>
            :
                <p className={`name ${nameClass}`}>{authorName}</p>
            }
        </BoxAutor>
        </> 
    );
}

export default ContentAuthor;


